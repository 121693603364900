.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}


.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 500px;
    height: 200px;
    background-color: #FFF;
    border-radius: 16px;
    //border-color: #FFA049;
}

.popup-inner .close-btn {
    position: absolute;
    bottom: 30px;
    right: 130px;
}

.popup-inner .save-btn {
    position: absolute;
    bottom: 30px;
    left: 130px;
}

.popup input {
    margin-top: 15px;
    max-width: 300px;
    width: 100%;
}