//Import Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}