.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 95%;
}

.container input,
textarea,
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #00000022;
  border-radius: 4px;
}

.consent input {
  width: auto;
}

.ueq input {
  width: auto;
}

.row {
  border-radius: 4px;
  margin: 12px 0;
  padding: 12px;
  display: grid;
  grid-template-areas: "item1 inputs item2";
  grid-template-columns: 3fr 8fr 3fr;
  justify-content: space-between;
}

@media (max-width: 1050px) {
  .row {
    grid-template-areas:
      "item1 item2"
      "inputs inputs";
    grid-template-columns: 1fr 1fr;
  }
}

.item1 {
  grid-area: item1;
  justify-self: start;
}

.item2 {
  grid-area: item2;
  justify-self: end;
}

.inputs {
  grid-area: inputs;
  display: grid;
  grid-auto-flow: column;
}

.row:nth-child(2n) {
  background-color: #00356622;
}


$blue-text-1: #003566;
$blue-text-2: #003566dd;
$blue-background-2: #003566;
$blue-background-3: #00356655;
$blue-background-4: #00356622;

.language{
  padding: 4px 12px;
  background-color: $blue-background-4;
  border-left: solid 8px $blue-background-3;
}

.language h3 {
  color: $blue-text-1;
}

.language p {
  color: $blue-text-2;
}

.language select {
  color: #ffffff;
  font-weight: 700;
  background-color: $blue-background-2;
}
