$orange: rgba(255, 160, 73, 1);

.ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 32px;
}

.button {
  background-color: #ffffff;
  color: $orange;
  cursor: pointer;
}


.logo {
  text-decoration: none;
  list-style-type: none;
  color: white;
  font-weight: 700;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 12px;
}

.navbar {
  background-color: $orange;
  padding: 12px 6px;
}
