.h1 {
  color: white;
  font-size: 50px;
}

.p {
  color: white;
  font-size: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
}

.image1 {
  float: left;
  max-width: 100%;
  height: auto;
}

.text2 {
  text-align: center;
  margin-top: 130px;
  font-weight: bold;
  color: black;
}

.text3 {
  text-align: center;
  color: black;
}

.button1 {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.btn1 {
  background-color: #FFA149;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  border-radius: 12px;
}

.btn2 {
  background-color: white;
  border: none;
  color: #FFA149;
  padding: 15px 32px;
  font-weight: bold;
  border-radius: 12px;
}

.confirmation_header {
  flex-grow: 1;
  background: #FFA149;
  text-align: center;
  padding: 10px;
}

.confirmation_main {
  flex-grow: 2;
  flex-direction: row;
  background: white;
}

@media (max-width: 900px) {
  .image1 {
    width: 100%;
    float: none;
  }
  
  .text2 {
    margin-top: 50px;
  }
  
  .button1 {
    margin-right: 0;
    margin-left: 0;
  }
  
  .wrapper, .image1, .text2, .text3, .button1, .btn1, .confirmation_header, .confirmation_main {
    width: 100%;
  }
}
