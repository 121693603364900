.infobanner {
    size: 30px;
    min-height: 200px;
    border-color: #FFA049;
    border-style: solid;
    display: flex;
    column-gap: 50px;
    background-color:white;
    border-radius: 16px;
    border-left-width: 200px;
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-right-width: 5px;
    box-sizing: content-box;
}

 .img {
    position: center;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-left: -130px;
    float: left;
 }

.title {
    font-family: "Inter", bold;
    margin-top: -20px;
    margin-left: 0px;
    padding: 50px;
    font-size: 30px;
    text-align: left;
    flex-direction: column;
}

.content {
    //width: 100%;
    font-family: "Lucida Console", "Courier New", monospace; 
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 100px;
    text-align: left;
    flex-direction: column;
}

.hint {
    margin-top: 20px;
    margin-right: 20px;
    display: block;
    position: relative;
    padding-left: 35px;
    height: 25px;

}

.hint input {
   
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: rgb(73, 58, 58);
  }
  

