.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 95%;
}

.container input,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #00000022;
  border-radius: 4px;
}

.container fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.container fieldset input {
  width: auto;
}
